/**
 * This file is included in every page and includes only core functionality to render pages properly
 */

// import the theme service and get this working as quickly as possible
import { ThemeService } from './theme/theme-service';
import { LocalStorageService } from './local-storage/local-storage-service';
import { ClientService } from './client-service/client-service';
import { ConsoleInterceptor } from './console-logger/console-logger';
import { AdService } from './ads-service/ad-service';
import { ToastService } from './toast-service/toast-service';
import { ModalService } from './modal-service/modal-service';
import { PreloaderService } from './preloader-service/preloader-service';
import { GoogleAdsProviderService } from './ads-service/google/google-ads-service';
import BUILD from "./client-service/b.json";
import { html } from 'lit';
import { BlogPostService } from './blog-post-service/blog-post-service';
import { GlobalAccessService } from './global-access-service/global-access-service';
import { SessionService } from './session-service/session-service';
import { UserEventsServiceComposite } from './user-events/user-events-service-composite';
import { EventListenerEventsService } from './user-events/event-listener-events-service';

// intercept the console
new ConsoleInterceptor(console);
console.log("B", BUILD.id);
console.log("Navigator", typeof navigator);

const clientService = new ClientService();
GlobalAccessService.getInstance().registerClientService(clientService);

const modalService = new ModalService();
const storageService = new LocalStorageService();

// force kickoff the theme service
new ThemeService(
    storageService,
    clientService
);

// bring in client service to handle urls
const adService = new AdService(
    clientService,
    new ToastService(),
    new UserEventsServiceComposite(
        clientService,
        new LocalStorageService()
    ),
    new ModalService()
);
adService.initialize();

// init session service
new SessionService(
    clientService,
    modalService,
    storageService,
    new EventListenerEventsService()
);

// preload resources
new PreloaderService().initializePreload();

// put google ad in footer if enabled
if (clientService.getConfig().footer.googleAdVisiblePercent >= Math.random()*100) {
    console.log("[Core] footer ads enabled, so attaching");
    const attr = GoogleAdsProviderService.AD_UNITS.primaryHorizontal;
    GoogleAdsProviderService.attachGoogleAdsScript(attr);
    document.addEventListener("DOMContentLoaded", async () => {
        const markup = GoogleAdsProviderService.getAdMarkup(attr, false);
        const adEl = document.getElementById("google-container");
        if (!adEl) {
            return;
        }

        adEl.innerHTML = 
            `
                <hr/>
                <h3 style="text-align: center">A word from our sponsors:</h3>
                ${markup}
            `;
        console.log("[Core] footer ads attached");

        // TODO: hacky, find better way of encapsulating this
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
    });
}

// show blog post modal if needed
const blogPostService = new BlogPostService(
    storageService,
    modalService,
    clientService
);