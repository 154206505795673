import { UserEvent } from "../../models/analytics-event";
import { HexakaiGameParams } from "../../models/hexakai-game-params";
import { initEventType } from "../init-event-type";

export interface BoardGeneratedEventData extends HexakaiGameParams {
    executionTime: number;
    dailyPuzzleTimestamp?: number;
    isSuccess: boolean;
    error: string;
    hintRandomSeedType: string;
    valueRandomSeedType: string;
};

export const boardGeneratedEvent =
    initEventType<BoardGeneratedEventData>("board_generated");