import { html } from "lit";
import { AdProviderService } from "../models/ad-provider-service";
import { ToastService } from "../toast-service/toast-service";
import { BaseAdProviderService } from "./base-ad-provider";
import { adDisplayRequestAcceptedEvent } from "../user-events/events/ad-display-request-accepted-event";
import { UserEventsServiceComposite } from "../user-events/user-events-service-composite";

export class UserRequestAdProviderDelegate extends BaseAdProviderService {

    private toastTitle = "Please support Hexakai";

    constructor(
        viewPercentage: number,
        private delegateService: AdProviderService,
        private toastService: ToastService,
        private userEventsServiceComposite: UserEventsServiceComposite
    ) {
        super(viewPercentage);
    }

    /**
     * If initialized, wait some time and show an ad popup
     */
    protected async doInitialize(delay: number): Promise<void> {
        return new Promise((resolve, reject) => {
            // wait up to x seconds to show the popup
            setTimeout(async () => {
                try {
                    await this.showToast();
                    resolve();
                } catch (err) {
                    console.log(`[UserRequestAdProviderDelegate] error caught:`, err);
                    reject(err);
                }
            }, delay);
        });
    }

    private showToast(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            console.log(`[UserRequestAdProviderDelegate] showing toast`);
            this.toastService.showToast(
                this.toastTitle,
                html`
                    <p>We'd love to keep this game free for everybody to play.</p>
                    <p>Would you like to support us by viewing an advertisement?</p>
                    <div style="display: flex; gap: 1em;">
                        <styled-button @click="${async () => {
                        this.toastService.hideToast(this.toastTitle);
                        try {
                            await this.delegateAd();
                            resolve();
                        } catch (err) {
                            reject(err);
                        }
                    }}">Yes</styled-button>
                        <styled-button @click="${() => {
                        this.toastService.hideToast(this.toastTitle);
                        resolve();
                    }}">No</styled-button>
                    </div>
                `,
                '/coin.png',
                Infinity
            );
        });
    }

    private delegateAd(): Promise<void> {
        console.log(`[UserRequestAdProviderDelegate] ad delegation initiated`);
        this.toastService.hideToast(this.toastTitle);
        this.toastService.showToast(
            'Thank You',
            'Thank you for your support! Your ad should appear within a few seconds.',
            '',
            5_000
        );
        this.userEventsServiceComposite.logEvent(
            adDisplayRequestAcceptedEvent(void 0)
        );
        return this.delegateService.initialize(Math.random() * 3_000);
    }
}