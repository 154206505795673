import { ToastNotification } from "../components/toast-notification";
import { GlobalAccessService } from "../global-access-service/global-access-service";

/**
 * Handles showing toast notifications
 * (TODO: could use a similar approach for modal dialogues)
 */
export class ToastService {

    // hacky way of enforcing singleton
    private static instance: ToastService;

    constructor() {
        if (ToastService.instance) {
            return ToastService.instance;
        }
        ToastService.instance = this;

        GlobalAccessService.getInstance().registerGlobalHandlers({
            toastService: this
        });
    }

    showToast(title: string, message: any, iconUrl?: string, duration = 10_000): void {
        const toastComponent = new ToastNotification();
        document.body.appendChild(toastComponent);

        setTimeout(() => {
            toastComponent.id = this.mapTitleToId(title);
            toastComponent.title = title;
            toastComponent.message = message;
            toastComponent.icon = iconUrl || "";
            toastComponent.duration = duration;
            toastComponent.visible = true;
        }, 100);
    }

    hideToast(title: string): void {
        const el = document.querySelector("#"+this.mapTitleToId(title));
        if (!el) {
            return;
        }
        el.parentNode?.removeChild(el);
    }

    private mapTitleToId(title: string): string {
        return `toast-`+title.replace(/[^a-zA-Z0-9-_:.]/g, "-");
    }
}