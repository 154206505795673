import { GameAchievement } from "../../models/game-achievement";
import { GameAchievementRecordService } from "../../models/game-achievement-record-service";
import { StorageService } from "../../models/storage-service";
import { GameAchievementService } from "../game-achievement-service";

export class GameAchievementRecordInAppStrategy implements GameAchievementRecordService {

    private achievementsKey = "achievements";

    constructor(
        private storageService: StorageService
    ) {}

    getServiceName(): string {
        return "Record Service Local";
    }

    async getAchievements(): Promise<GameAchievement[]> {
        const savedAchievements = await this.storageService.get(this.achievementsKey) as any;
        const ids: string[] = savedAchievements ? savedAchievements.saved || [] : [];
        return ids.map(id => GameAchievementService.getAchievementFromId(id));
    }

    async hasAchievement(id: string): Promise<boolean> {
        const savedAchievements = await this.storageService.get(this.achievementsKey) as any;
        const ids: string[] = savedAchievements ? savedAchievements.saved || [] : [];
        return ids.includes(id);
    }

    async recordAchievement(achievement: GameAchievement): Promise<void> {
        const savedAchievements = await this.storageService.get(this.achievementsKey) as any;

        await this.storageService.set(this.achievementsKey, {
            saved: [... new Set([...savedAchievements?.saved || [], achievement.id])]
        });
    }

}