import { UserEvent } from "../models/analytics-event";
import { UserEventsService } from "../models/user-events-service";
import { EventEmitter } from "eventemitter3";

export class EventListenerEventsService implements UserEventsService {

    private static instance: EventListenerEventsService;

    private eventEmitter = new EventEmitter<any>();

    constructor() {
        if (EventListenerEventsService.instance) {
            return EventListenerEventsService.instance;
        }

        EventListenerEventsService.instance = this;
    }

    async logEvent(event: UserEvent<any>): Promise<void> {
        this.eventEmitter.emit(event.eventName, event);
    }

    onEvent(eventName: string | string[], callback: (event: UserEvent<any>) => void): void {
        if (!Array.isArray(eventName)) {
            eventName = [eventName];
        }
        for (const event of eventName) {
            this.eventEmitter.addListener(event, callback);
        }
    }

}