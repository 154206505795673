import { html } from "lit";
import { ModalService } from "../modal-service/modal-service";
import { StorageService } from "../models/storage-service";
import { BLOG_DOCUMENTS } from "../scripts/blog-paths";
import { ClientService } from "../client-service/client-service";

export class BlogPostService {

    private static instance: BlogPostService;
    private sessionKey = "last-blog-seen";

    private lastPost = BLOG_DOCUMENTS[0];

    constructor(
        private storageService: StorageService,
        private modalService: ModalService,
        private clientService: ClientService
    ) {
        if (BlogPostService.instance) {
            return BlogPostService.instance;
        }

        BlogPostService.instance = this;
        
        this.init();
    }

    private async init(): Promise<void> {
        await this.checkLastPostFromSession();

        setInterval(() => {
            this.checkLastPostRegistered();
        }, 1_000 * 60 * 60 * 24);
    }

    private async checkLastPostFromSession(): Promise<void> {
        const sessionPost = await this.storageService.get<any>(this.sessionKey);
        if (!sessionPost || !sessionPost.post) {
            this.storageService.set(this.sessionKey, {
                post: this.lastPost
            });
        } else {
            if (sessionPost.post.path !== this.lastPost.path) {
                this.storageService.set(this.sessionKey, {
                    post: this.lastPost
                });
                this.showNewBlogPostModal();
            }
        }
    }

    private async checkLastPostRegistered(): Promise<void> {
        const newPost = await fetch("/last-blog.json").then(res => res.json());
        if (newPost.path === this.lastPost.path) {
            return;
        }

        this.lastPost = newPost;

        // show a message to view the latest blog post
        this.showNewBlogPostModal();
    }

    private showNewBlogPostModal(): void {
        const blogPath = this.clientService.createLocalLink(
            "/blog/"+this.lastPost.path,
            {},
            true
        );

        const modalID = "new-blog-post";
        const modalTitle = "New Blog Post";
        const template = html`
            <style>
                .blog-description {
                    display: flex;
                    flex-direction: column;
                }

                .blog-url {
                    align-self: center;
                }

                .blog-img {
                    align-self: center;
                }

                .blog-buttons-row {
                    display: flex;
                    gap: 1em;
                }
            </style>
            <div class="blog-description">
                <p>
                    We've published a new blog post:
                    <br><b>${this.lastPost.metadata.meta_title}</b>
                </p>
                <a class="blog-url" href="${blogPath}">
                    <img-smart class="blog-img" src="${this.lastPost.metadata.display_img}"></img-smart>
                </a>
                <p>
                    <b>Description:</b>
                    <br>${this.lastPost.metadata.meta_description}
                </p>
                <hr style="width: 95%"/>

                <p><b>Would you like to read it?</b></p>
                <br>
            </div>
            <div class="blog-buttons-row">
                <styled-button @click="${() => window.location.href = blogPath}">Read New Post</styled-button>
                <styled-button @click="${() => this.modalService.hideModal(modalID)}">Close</styled-button>
            </div>
        `;

        this.modalService.showModal(
            modalID,
            modalTitle,
            template
        );
    }
}