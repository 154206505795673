import { UserEventsServiceComposite } from "../analytics/analytics-service";
import { ClientService } from "../client-service/client-service";
import { ModalService } from "../modal-service/modal-service";
import { AdProviderService } from "../models/ad-provider-service";
import { AdProviders } from "../models/client-config";
import { ToastService } from "../toast-service/toast-service";
import { AuthorBooksAdProviderService } from "./author-books/author-books-ad-provider-service";
import { BookOfTheMonthAdProviderService } from "./book-of-the-month/book-of-the-month-ad-provider";
import { GoogleAdsProviderService } from "./google/google-ads-service";
import { InfolinksAdProviderService } from "./infolinks/infolinks-ads-service";
import { UserRequestAdProviderDelegate } from "./user-request-ad-provider-delegate";

export class AdService implements AdProviderService {

    private static instance: AdService;
    private isInitialized = false;

    constructor(
        private clientService: ClientService,
        private toastService: ToastService,
        private UserEventsServiceComposite: UserEventsServiceComposite,
        private modalService: ModalService
    ) {
        if (AdService.instance) {
            return AdService.instance;
        }

        AdService.instance = this;
        (window as any).adService = {
            showAd: () => this.initialize(0, 100, true)
        };
    }

    async initialize(delay = Math.random() * 5_000, inputViewPercentage = -1, reinitialize?: boolean): Promise<void> {
        if (this.isInitialized && !reinitialize) {
            return;
        }
        this.isInitialized = true;

        const adsConfig = this.clientService.getConfig().ads;

        const { provider } = this.selectProvider(adsConfig.adProviders);
        
        let viewPercentace: number = inputViewPercentage === -1
            ? adsConfig.viewPercentage
            : inputViewPercentage;

        let baseService: AdProviderService;
        if (adsConfig.requestDisplayPermission.enabled) {
            const delegateService = this.getAdProvider(provider, 100);
            baseService = new UserRequestAdProviderDelegate(
                viewPercentace,
                delegateService,
                this.toastService,
                this.UserEventsServiceComposite
            );
        } else {
            baseService = this.getAdProvider(provider, viewPercentace);
        }

        try {
            return await baseService.initialize(delay);
        } catch (err) {
            console.warn(`[AdService] error caught while rendering ad, so using fallback strategy`, err);
            return await this.initializeFallbackAd();
        }
    }

    private selectProvider(
        providers: {provider: AdProviders; percentage: number}[]
    ): {provider: AdProviders; percentage: number} {
        let amount = Math.floor(Math.random() * 100);
        for (const provider of providers) {
            amount -= provider.percentage;
            if (amount <= 0) {
                return provider;
            }
        }

        return providers[providers.length - 1];
    }

    private getAdProvider(
        provider: AdProviders,
        viewPercentage: number
    ): AdProviderService {
        switch (provider) {
            case AdProviders.infolinks:
                return new InfolinksAdProviderService(viewPercentage);
            case AdProviders.bookOfTheMonth:
                return new BookOfTheMonthAdProviderService(
                    viewPercentage,
                    this.modalService
                );
            case AdProviders.authorBook:
                return new AuthorBooksAdProviderService(
                    viewPercentage,
                    this.modalService
                );
            case AdProviders.google:
                return new GoogleAdsProviderService(
                    viewPercentage,
                    this.modalService,
                    this.clientService
                );
        }
    }
    
    /**
     * If an error is caught with one of the ad units, initialize a fallback
     */
    private async initializeFallbackAd(): Promise<void> {
        console.log(`[AdService] initializing fallback ad`);
        const service = this.getAdProvider(AdProviders.authorBook, 100);
        return service.initialize(0);
    }
}