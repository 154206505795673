import { AdProviders, ClientConfig } from "../models/client-config";

export const IOS_APP_LOCAL_CONFIG: ClientConfig = {
    body: {
        appBodyScrollDisabled: true,
        fullScreenEnabled: false,
        gameCompleteAdVisible: true,
        styledButton: {
            pointerUpCssForce: false
        },
        navMenuSubmenuEnabled: false
    },
    tutorial: {
        type: "interactive"
    },
    footer: {
        clientText: "iOS System",
        googleAdVisiblePercent: 100,
    },
    gameBoard: {
        thickBorders: false,
        boldCellValues: true,
        defaultHexHeight: "max(5vw, 5vh)",
        hexFontMultiplier: 0.31,
        colorInfoMessage: `To mark a cell with a color, double tap, or hold your finger on the cell until the color selection appears.`,
        zoomMessage: `To zoom in and out, use two fingers to make the board bigger or smaller, or use the icons on the top right.`,
        showAppMessage: false,
        showCellConstraintsHover: true,
        puzzleCompletionModal: {
            enabled: true
        },
        generatorPatternCreate: {
            enabled: true,
            experimentalEnabled: false
        },
        hexCell: {
            rightClickStrategy: 'wait',
            numPencilsVisible: 2
        }
    },
    gameAchievements: {
        emitStrategy: 'none',
        displayToast: false,
        displayAchievementsView: true
    },
    urls: {
        alterScheme: true,
    },
    ads: {
        requestDisplayPermission: {
            enabled: true,
        },
        adProviders: [{
            provider: AdProviders.google,
            percentage: 90
        }, {
            provider: AdProviders.bookOfTheMonth,
            percentage: 0
        }, {
            provider: AdProviders.authorBook,
            percentage: 10
        }],
        viewPercentage: 25
    },
    analytics: {
        logEvents: true
    },
    clientDispatcher: {
        readyResolveStrategy: 'immediate'
    },
    dailyPuzzles: {
        future: false
    },
    controls: {
        window: false
    },
    themes: {
        extendedThemesEnabled: true
    },
    share: {
        navigatorEnabled: true
    }
};