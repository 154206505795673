import { UserEvent } from "../../models/analytics-event";
import { HexakaiGameParams } from "../../models/hexakai-game-params";
import { initEventType } from "../init-event-type";
import { BoardCompletedEventData } from "./board-completed-event";

export interface DailyBoardCompletedEventData extends BoardCompletedEventData {
    dayTimestamp: number;
}

export const dailyBoardCompletedEvent
    = initEventType<DailyBoardCompletedEventData>("daily_board_completed");