import { LitElement, html, css, nothing } from 'lit';
import { customElement, eventOptions, property } from 'lit/decorators.js';
import { ref, createRef } from 'lit/directives/ref.js';

@customElement('modal-layover')
export class ModalLayover extends LitElement {
    static styles = css`
        :host {}

        .layover-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 100%;
            max-height: 100%;
            display: block;
            background-color: var(--modal-background-color, rgba(0, 0, 0, 0.5));
            z-index: 1000;
        }

        .layover {
            position: relative;
            width: 50%;
            max-width: min(var(--max-width, 600px), 90vw);
            max-height: 70vh;
            min-height: var(--min-height);

            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            background: var(--background-color);
            border: 2px solid var(--body-color);
            border-radius: 10px;
            box-shadow: 0 4px 6px rgba(0,0,0,0.1);
            padding-top: 40px; 

            left: 50%;
            top: 50%;

            transform: translate(-50%, -50%);
        }

        .layover-animate {
            animation: slideIn 0.2s ease-out forwards;
        }

        @keyframes slideIn {
            from {
                transform: translate(-50%, 150%); /* Start from below the view */
            }
            to {
                transform: translate(-50%, -50%);
            }
        }

        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            border: none;
            background: none;
            font-size: 1.2em;
            cursor: pointer;
            color: var(--body-color);
            border: 1px solid var(--body-color);
            border-radius: 10px;
        }

        .close-button:hover {
            background-color: var(--button-hover-background-color) !important;
        }

        .title {
            /*font-size: 1.5em;*/
            margin-top: 0;
            padding-top: 0;
            margin-bottom: 20px;
        }

        .layover-container-closed {
            display: none;
        }

        @media screen and (max-width: 800px) {
            .layover {
                width: 80%;
            }
        }
    `;

    private escapeEvent: Event | null = null;

    @property({ attribute: false }) contentTemplate: any = html``;
    @property({ type: String }) title = '';
    @property({ type: Boolean, reflect: true }) open = false;

    private contentRef = createRef();

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    @eventOptions({ capture: true })
    handleClose() {
        this.dispatchEvent(new CustomEvent('modal-hide', { bubbles: true, composed: true }));
    }

    render() {
        console.log("[ModalLayover] rendering");
        return html`
                <style>
                    ${ModalLayover.styles}
                </style>
                <div class="layover-container ${this.open ? "" : "layover-container-closed"}" @click="${(e: MouseEvent) => this._onClickOutside(e)}">
                    <div class="layover ${this.open ? "layover-animate" : ""}" @click="${(e: MouseEvent) => this._onModalClick(e)}">
                        <button class="close-button" @click="${this.hide}">&#10005;</button>
                        ${this.title ? html`<h2 class="title">${this.title}</h2>` : nothing}
                        ${this._renderContent()}
                    </div>
                </div>
            `;
    }

    _renderContent() {
        // Check if contentTemplate is an HTMLElement or a lit-html template
        if (this.contentTemplate instanceof HTMLElement) {
            // Render an empty div where we'll manually append the element in firstUpdated
            return html`<div ${ref(this.contentRef)}></div>`;
        } else if (this.contentTemplate) {
            // Directly render the lit-html template
            return this.contentTemplate;
        } else {
            return nothing;
        }
    }

    firstUpdated() {
        // If contentTemplate is an HTMLElement, append it to the div
        if (this.contentTemplate instanceof HTMLElement) {
            const container = this.contentRef.value;
            if (container) {
                container.innerHTML = ''; // Clear existing content if necessary
                container.appendChild(this.contentTemplate);
            }
        }
    }


    show() {
        this.open = true;
        this.requestUpdate(); // Force re-render to apply the animation class
        this.dispatchEvent(new CustomEvent('modal-show'));

        this.escapeEvent = document.addEventListener('keydown', (e: any) => {
            if (e.key === 'Escape') {
                this.hide();
            }
        }) as any;
    }

    hide() {
        this.open = false;
        this.handleClose();
        this.dispatchEvent(new CustomEvent('modal-hide'));
        document.removeEventListener('keydown', this.escapeEvent as any);
        this.escapeEvent = null;
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('contentTemplate')) {
            this.requestUpdate();
        }
    }

    private _onClickOutside(event: MouseEvent) {
        if (event.target === event.currentTarget) {
            this.hide();
        }
    }

    private _onModalClick(event: MouseEvent) {
        event.stopPropagation();
    }
}
