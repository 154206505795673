import { RngParams } from "./rng-params";
import { ShufflerParams } from "./shuffler-params";

export enum HexakaiGameDifficulty {
    easy = "easy",
    medium = "medium",
    difficult = "difficult",
    ultraDifficult = "ultra difficult"
};
export const HexakaiGameDifficulties: HexakaiGameDifficulty[] = Object.values(HexakaiGameDifficulty); 

export interface HexakaiGameParams {
    gameSize: number;
    difficulty: HexakaiGameDifficulty;
    generatorId?: string;
}