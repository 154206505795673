export class PromiseSubject<T> {

    private _resolve!: Function;
    private _reject!: Function;
    private promise = new Promise<T>((resolve, reject) => {
        this._resolve = resolve;
        this._reject = reject;
    });

    constructor() {}

    resolve(value: T | Promise<T>): PromiseSubject<T> {
        this._resolve(value);
        return this;
    }

    reject(value: any | Promise<any>): PromiseSubject<T> {
        this._reject(value);
        return this;
    }

    getPromise(): Promise<T> {
        return this.promise;
    }
}