import { GameAchievement } from "../../models/game-achievement";
import { GameAchievementDisplayStrategy } from "../../models/game-achievement-display-strategy";
import { GameAchievementRecordService } from "../../models/game-achievement-record-service";
import { StorageService } from "../../models/storage-service";
import { ToastService } from "../../toast-service/toast-service";

export class GameAchievementDisplayInAppStrategy implements GameAchievementDisplayStrategy {

    constructor(
        private recordService: GameAchievementRecordService,
        private toastService: ToastService,
        private record: boolean
    ) {}

    getServiceName(): string {
        return "Display Toast";
    }

    async dispatch(achievement: GameAchievement): Promise<void> {
        if (await this.recordService.hasAchievement(achievement.id)) {
            console.log("[GameAchievementDisplayInAppStrategy] achievement already saved, so not displaying", achievement);
            return;
        }

        if (this.record) {
            await this.recordService.recordAchievement(achievement);
        }
        
        console.log("[GameAchievementDisplayInAppStrategy] displaying achievement", achievement);
        this.toastService.showToast("Achievement Unlocked!", achievement.name + "!", achievement.iconUrl);
    }
}