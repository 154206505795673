export class ConsoleInterceptor {

    private static instance: ConsoleInterceptor | null = null;

    private logs: string[] = [];

    constructor(private console: Console) {
        if (ConsoleInterceptor.instance) {
            return ConsoleInterceptor.instance;
        }

        ConsoleInterceptor.instance = this;
        this.hookMethods();
    }

    private hookMethods(): void {
        const logTypes: Array<keyof Console> = ['log', 'warn', 'error', 'info', 'debug'];
        logTypes.forEach(method => {
            // @ts-ignore
            const originalMethod = this.console[method].bind(this.console);
            // @ts-ignore
            this.console[method] = (...args: any[]) => {
                this.logs.push(`${method.toUpperCase()}: ${args.map(arg => this.stringifyArg(arg)).join(' ')}`);
                originalMethod(...args);
            };
        });
    }

    private stringifyArg(arg: any): string {
        try {
            return JSON.stringify(arg);
        } catch (ex) {
            return `${arg}`;
        }
    }

    public getLogs(): string[] {
        return this.logs;
    }

    public clearLogs(): void {
        this.logs = [];
    }
}