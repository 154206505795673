import { css, html } from "lit";
import { BaseAdProviderService } from "../base-ad-provider";
import { ModalService } from "../../modal-service/modal-service";
import { AUTHOR_BOOK_ENTRIES } from "./author-books-config";

export class AuthorBooksAdProviderService extends BaseAdProviderService {

    constructor(
        viewPercentage: number,
        private modalService: ModalService
    ) {
        super(viewPercentage)
    }

    protected async doInitialize(delay: number): Promise<void> {
        const book = AUTHOR_BOOK_ENTRIES[
            Math.floor(Math.random() * AUTHOR_BOOK_ENTRIES.length)
        ];
        console.log(`[AuthorBooksAdProviderService] showing ad for:`, book);

        const modalId = 'author-book-ad';

        const fullTitle = [
            html`${book.title}`,
            book.subTitle ? html`: <i>${book.subTitle}</i>` : html``
        ];

        // TODO: need to account for scenario where there are more than two authors!
        const description = typeof book.description === 'string'
            ? html`${book.description}`
            : book.description;

        const template = html`
            <style>
                .book-ad-container {
                    text-align: left;
                }

                .book-img {
                    float: left;
                    width: 25%;
                    max-width: 500px;
                    min-height: 100px;
                    margin-right: 1em;
                    margin-bottom: 0.5em;
                    border: 1px solid var(--body-color);
                }

                .book-box {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    list-style-position: inside; /* Optional: Moves the bullet inside the list item's box */
                }

                .book-description {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 10;   /* Number of lines to show */
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                /* Clear the float for the book-buttons so it does not get wrapped */
                .book-buttons {
                    clear: both; /* Ensure this clears any floated elements */
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    margin-top: 1em;
                }

                .author-message {
                    font-size: 80%;
                }

                .get-book {
                    margin-top: 0px;
                }
            </style>

            <div class="book-ad-container">
                <h2>${fullTitle}</h2>
                <p class="author-message">This book is written by Brandon Quinn, creator of Hexakai. Please consider adding this to your bookshelf!</p>
                <div class="book-description">
                    <a href="${book.amazonUrl}" target="_blank">
                        <img-smart class="book-img" src="${book.coverImgUrl}"></img-smart>
                    </a>
                    <p class="get-book"><b>Get the book:</b> on <a href="${book.amazonUrl}" target="_blank">Amazon</a></p>
                    <hr />
                    ${description}
                </div>
                <div style="clear: both;"></div> <!-- Clear float here before the buttons -->
                <br/>
                <div class="book-buttons">
                    <styled-button @click="${() => {
                        window.open(book.amazonUrl, "_blank");
                        this.modalService.hideModal(modalId);
                    }}">Get the Book!</styled-button>
                    <styled-button @click="${() => {
                        this.modalService.hideModal(modalId);
                    }}">Close</styled-button>
                </div>
            </div>

        `;

        setTimeout(() => {
            this.modalService.showModal(
                modalId,
                '',
                template,
                {
                    maxWidth: '750px'
                }
            );
        }, delay);
    }
}