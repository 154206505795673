import { UserEvent } from "../models/analytics-event";
import { ClientService } from "../client-service/client-service";
import { UserEventsService } from "../models/user-events-service";
import { adDisplayRequestAcceptedEvent } from "./events/ad-display-request-accepted-event";
import { boardCompletedEvent } from "./events/board-completed-event";
import { boardStartedEvent } from "./events/board-started-event";
import { dailyBoardCompletedEvent } from "./events/daily-board-completed-event";
import { hintClickedEvent } from "./events/hint-clicked-event";
import { playAgainClickedEvent } from "./events/play-again-clicked-event";

export class GoogleUserEventsService implements UserEventsService {

    private static instance: GoogleUserEventsService;
    private static TRACKING_ID = "G-BVYEEDRKKT";
    private initPromise!: Promise<void>;

    private static ALLOWED_EVENTS = new Set([
        adDisplayRequestAcceptedEvent,
        boardCompletedEvent,
        boardStartedEvent,
        dailyBoardCompletedEvent,
        hintClickedEvent,
        playAgainClickedEvent
    ].map(fn => fn({} as any).eventName));

    constructor(
        private clientService: ClientService
    ) {
        if (GoogleUserEventsService.instance) {
            return GoogleUserEventsService.instance;
        }

        GoogleUserEventsService.instance = this;
        this.init();
    }

    async logEvent(event: UserEvent<any>): Promise<void> {
        if (!this.clientService.getConfig().analytics.logEvents) {
            return;
        }

        if (!GoogleUserEventsService.ALLOWED_EVENTS.has(event.eventName)) {
            return;
        }

        await this.initPromise;

        console.log("[GoogleUserEventsService] sending event", event);

        if ((window as any).gtag) {
            (window as any).gtag('event', event.eventName, event.data);
        } else {
            console.warn("[GoogleUserEventsService] failed to send event due to lack of gtag", event);
        }
    }

    private async init(): Promise<void> {
        this.initPromise = new Promise((resolve) => {
            if (!this.clientService.getConfig().analytics.logEvents) {
                resolve();
                return;
            }
            console.log("[GoogleUserEventsService] loading Google Analytics");

            // Initialize the dataLayer and gtag function
            const global = window as any;
            global.dataLayer = global.dataLayer || [];
            global.gtag = function() {
                global.dataLayer.push(arguments);
                console.log("[GoogleAnalytics] datalayers pushed", arguments)
            }

            // Load the Google Analytics script asynchronously
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${GoogleUserEventsService.TRACKING_ID}`;

            // Load the Google Analytics tracking ID
            script.onload = () => {
                global.gtag('js', new Date());
                global.gtag('config', GoogleUserEventsService.TRACKING_ID);
                resolve();
            };

            document.head.appendChild(script);
        });
    }
}