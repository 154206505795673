import { GameAchievement } from "../../models/game-achievement";
import { GameAchievementDisplayStrategy } from "../../models/game-achievement-display-strategy";
import { GameAchievementRecordService } from "../../models/game-achievement-record-service";

export class GameAchievementDisplayEmitStrategy implements GameAchievementDisplayStrategy {

    constructor(
        private recordService: GameAchievementRecordService
    ) {}

    getServiceName(): string {
        return "Display Emit";
    }

    async dispatch(achievement: GameAchievement): Promise<void> {
        console.log("[GameAchievementDisplayEmitStrategy] emitting achievement", achievement);

        this.recordService.recordAchievement(achievement);
    }
}