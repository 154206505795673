import { css, html } from "lit";
import { ModalLayover } from "../components/modal-layover";
import { GlobalAccessService } from "../global-access-service/global-access-service";

export class ModalService {

    constructor() {
        GlobalAccessService.getInstance().registerGlobalHandlers({
            modalService: this
        });
    }

    showModal(
        id: string,
        title: string,
        contentTemplate: any = html``,
        css: {[key: string]: string} = {}
    ) {
        this.hideModal(id);

        const modal = new ModalLayover();
        modal.id = id;
        modal.title = title;
        modal.contentTemplate = contentTemplate;

        for (const cssKey in css) {
            switch (cssKey) {
                case "maxWidth":
                    modal.style.setProperty("--max-width", css[cssKey]);
                    break;
                case "minHeight":
                    modal.style.setProperty("--min-height", css[cssKey]);
                    break;
                default:
                    modal.style.setProperty(cssKey, css[cssKey]);
                    break;
            }
        }

        document.body.appendChild(modal);
        modal.show();
    }

    hideModal(id: string): void {
        const existing = document.getElementById(id);
        if (existing) {
            document.body.removeChild(existing);
        }
    }
}