import { html } from "lit";

export interface AuthorBook {
    title: string;
    subTitle: string;
    description: any;
    amazonUrl: string;
    coverImgUrl: string;
};

export const AUTHOR_BOOK_ENTRIES: AuthorBook[] = [
    {
        title: 'Method Matters',
        subTitle: 'A Practical Guide to Achieving Your Goals Through Critical Thinking',
        description: html`
            <p><i>"A simple introduction to critical thinking and its practical benefits that readers will find easy to grasp."</i> - Kirkus Reviews</p>

            <p>The methods of observation, reasoning, and critical thinking have been developed and refined countless times throughout the centuries in all walks of life. These methods have driven innovations in every industry and enabled individuals and organizations to achieve their goals and prosper. Unfortunately for all of us, the basics of critical thinking are not effectively taught during our time in public schools and universities.</p>
            <p>In <i>Method Matters</i>, Quinn outlines the steps of observation, introduces different types of reasoning including <i>goal-based reasoning</i>, delves into methods of creativity to help you overcome hurdles, and brings it all together to teach you how to plan, execute, and achieve your goals.</p>
            <p><i>Method Matters</i> also outlines the pitfalls that can hinder your rationality. Quinn showcases failures in critical thinking, including an experiment from flat Earthers that proves the Earth is round, and a strange series of events led by the author of <i>Sherlock Holmes</i> himself. He then outlines successes in critical thinking, including John Snow’s research on Cholera that helped save thousands of lives.</p>
            <p>Learning to execute the critical thinking process effectively is crucial to advancing your career, business, and personal objectives. This book gives you the deep understanding necessary to take your goals to the next level.</p>
        `,
        amazonUrl: 'https://amzn.to/3XCbXju',
        coverImgUrl: 'https://brandon-quinn.com/wp-content/uploads/2024/08/KDP.jpg'
    },
    {
        title: 'Mental Mosaics',
        subTitle: 'A Unique Collection of 200 Puzzles on Logic, Lies, and the Limitless',
        description: html`
            <p><b>Mental Mosaics</b> is a collection of 200 unique puzzles and challenges on an eclectic range of subjects, including clocks and calendars, liars, demons, chess boards, rotatable words, and hidden infinities. Each chapter is a self-contained unit that focuses on its own unique subject, starts with one or two simple puzzles to set the tone, progresses to a number of moderately difficult puzzles, and concludes with a small number of highly difficult puzzles to test your mastery. This book includes 184 novel puzzles created by the author, Brandon Quinn, and ends with 16 puzzles that are famous across the world, concluding with “The Hardest Logic Puzzle Ever.”</p>
        `,
        amazonUrl: 'https://amzn.to/3SKrWK4',
        coverImgUrl: 'https://brandon-quinn.com/wp-content/uploads/2024/01/MentalMosaicsCover.jpg'
    },
    {
        title: 'Hexakai',
        subTitle: 'A Sudoku-inspired game of hexagons',
        description: html`
            <p><i>“Hexakai offers a fresh twist on logic puzzles, delivering a familiar yet novel challenge that Sudoku enthusiasts will love. With varying difficulty levels and grid size options, it’s perfect for a quick break or an engaging brain workout.”</i> – David Clamage, Rangsk</p>

            <p>Dive into the captivating world of Hexakai, a unique and challenging hexagonal puzzle game, with this comprehensive puzzle book. Perfect for enthusiasts and newcomers alike, this book offers a delightful mix of engaging puzzles, strategic insights, and a touch of mathematics to enhance your puzzle-solving experience.</p>
        `,
        amazonUrl: 'https://amzn.to/3VZIjmm',
        coverImgUrl: 'https://brandon-quinn.com/wp-content/uploads/2024/07/hexakai-cover-concept.jpg'
    }
];