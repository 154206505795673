import { HexakaiBoardState } from "../models/hexakai-board-state";

/**
 * Useful utility for operating over hexakai board data, not over a specific instance
 * This is generic to the size of the game, higher values make bigger games
 */
export class HexakaiBoardData {

    public static GAME_SIZES = [3, 5, 7].concat(
        new Array(17 - 8).fill(0).map((_, ind) => 8 + ind)
    );

    public static DEFAULT_GAME_SIZE = 10;

    // data on the board size
    private numRows: number;
    private largestRowWidth: number;
    private largestRowIndex: number;
    private smallestRowWidth = 1;
    private numColsCache: number[] = [];

    // data on cells
    private cellValues: string[] = [];

    /**
     * Construct the game with a game size
     * Default is 10 for digits 0-9.
     * Greater than 10 will use letters
     */
    constructor(private gameSize = HexakaiBoardData.DEFAULT_GAME_SIZE) {
        if (gameSize !== 1 && HexakaiBoardData.GAME_SIZES.indexOf(gameSize) === -1) {
            throw new Error("Invalid game size! " + gameSize);
        }

        this.numRows = gameSize * 2 - 1;
        this.largestRowWidth = gameSize;
        this.largestRowIndex = gameSize - 1;

        // push numbers
        for (let i = 0; i < Math.min(gameSize, 10); i++) {
            this.cellValues.push(`${i}`);
        }

        // push letters if numbers greater than 10
        for (let i = 10; i < gameSize; i++) {
            const charCode = "A".charCodeAt(0) + i - 10;
            const char = String.fromCharCode(charCode);

            this.cellValues.push(`${char}`);
        }

        // cache the number of columns
        for (let i = 0; i < this.numRows; i++) {
            this.numColsCache.push(
                this.computeNumCols(i)
            );
        }
    }

    public getCellValues(): string[] {
        return this.cellValues;
    }

    /**
     * Get the size of this game
     */
    public getGameSize(): number {
        return this.gameSize;
    }

    /**
     * Get the number of rows in this game
     */
    public getNumRows(): number {
        return this.numRows;
    }

    /**
     * Get the index of the largest row
     */
    public getLargestRowIndex(): number {
        return this.largestRowIndex;
    }

    public getLargestRowWidth(): number {
        return this.largestRowWidth;
    }

    public getSmallestRowWidth(): number {
        return this.smallestRowWidth;
    }

    /**
     * Get the number of columns in a particular row
     */
    private computeNumCols(row: number): number {
        if (row === this.largestRowIndex) {
            return this.largestRowWidth;
        }

        if (row < this.largestRowIndex) {
            return row + 1;
        }

        return this.numRows - row;
    }

    /**
     * Get the cached num cols
     */
    public getNumCols(row: number): number {
        return this.numColsCache[row];
    }

    /**
     * Get the number of cells in a row, starting at a column
     */
    public getNumCellsInRowFromStart(row: number, col: number): number {
        return this.getNumCols(row) - col;
    }

    /**
     * Get the number of cells in a left col, starting at a particular row and column
     */
    public getNumCellsInColLeftFromStart(row: number, col: number): number {
        const base = this.getLargestRowWidth();
        return base - (Math.min(row, base - 1) - col);
    }

    /**
     * Get the number of cells in a right col, starting at a particular row and column
     */
    public getNumCellsInColRightFromStart(row: number, col: number): number {
        const base = this.getLargestRowWidth();
        return base - col - Math.max(0, row - (base - 1));
    }

    /**
     * Generate a board state with a default item, based on input or callback
     * Note: row 0 corresponds to the top row
     */
    public createBoardState<T>(defaultItem: T | ((row: number, col: number) => T)): HexakaiBoardState<T> {
        // if the item is not a function, wrap it for simplicity
        let makeItem = defaultItem as ((row: number, col: number) => T);
        if (typeof defaultItem !== 'function') {
            makeItem = (row: number, col: number) => defaultItem;
        }

        // generate the top of the board
        const boardData: T[][] = [];
        for (let i = 0; i < this.largestRowIndex; i++) {
            const row: T[] = [];
            for (let j = 0; j <= i; j++) {
                row.push(makeItem(i, j));
            }
            boardData.push(row);
        }

        // generate the middle of the board
        const midRow: T[] = [];
        for (let j = 0; j < this.largestRowWidth; j++) {
            midRow.push(makeItem(this.getLargestRowIndex(), j));
        }
        boardData.push(midRow);

        // generate the bottom of the board
        for (let i = this.largestRowIndex - 1; i >= 0; i--) {
            const row: T[] = [];
            for (let j = 0; j <= i; j++) {
                row.push(makeItem(boardData.length, j));
            }
            boardData.push(row);
        }

        return {
            gameSize: this.getGameSize(),
            cells: boardData
        };
    }

    public calculateNumConstraints(): HexakaiBoardState<number> {
        const state = this.createBoardState((row, col) => {
            // it is constrained by 2(n-1) for diagonals
            let numConstraining = 2*(this.getGameSize() - 1);

            // count number in row, subtract by 1
            numConstraining += this.getNumCellsInRowFromStart(row, 0) - 1;

            return numConstraining;
        });

        return state;
    }

    public boardStateToCsv(state: HexakaiBoardState<any>): string {
        const items: number[] = [];
        for (let row=0; row<this.getNumRows(); row++) {
            for (let col=0; col<this.getNumCols(row); col++) {
                items.push(state.cells[row][col]);
            }
        }

        return items.join(",");
    }

    /**
     * Make a simple string, where spaces are empty cells and single chars
     * are values
     * 
     * Won't work for display settings with multi-char values
     */
    public boardStateToSimpleString(state: HexakaiBoardState<any>): string {
        const items: number[] = [];
        for (let row=0; row<this.getNumRows(); row++) {
            for (let col=0; col<this.getNumCols(row); col++) {
                items.push(state.cells[row][col] || " ");
            }
        }

        return items.join("");
    }
}