export enum Sounds {
    success = "success",
    error = "error",
    start = "start"
}

export const SOUND_PATHS = {
    [Sounds.start]: "./success-pleasant-interface-alert-6-message-hint-ding-SBA-300421165.wav",
    [Sounds.error]: "./error-metallic-stab-error-sound-SBA-300463060.wav",
    [Sounds.success]: "./start-fairy-glitter-shine-2-SBA-300419812.wav"
}