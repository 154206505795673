import { html } from "lit";

export interface BookOfTheMonth {
    month: number; // 1-indexed
    year: number;
    authors: string[];
    title: string;
    subTitle: string;
    description: any;
    blogUrl: string;
    amazonUrl: string;
    coverImgUrl: string;
};

export const BOOK_OF_THE_MONTH_ENTRIES: BookOfTheMonth[] = [
    {
        month: 9,
        year: 2024,
        authors: ['David Robson'],
        title: 'The Intelligence Trap',
        subTitle: 'Why Smart People Make Dumb Mistakes',
        description: html`
            <p><b>A “startling, provocative and potently useful” (James McConnachie, Times UK) examination of the stupid things intelligent people do.</b></p>
            <p>The Intelligence Trap explores cutting-edge ideas in our understanding of intelligence and expertise, including “motivated reasoning,” “meta-forgetfulness,” and “functional stupidity.” David Robson reveals the surprising ways that even the brightest minds and most talented organizations can go wrong? From some of Thomas Edison’s worst ideas to failures at NASA―while offering practical advice to avoid mistakes based on the timeless lessons of Benjamin Franklin, Richard Feynman, and Daniel Kahneman.</p>`,
        blogUrl: 'https://brandon-quinn.com/the-intelligence-trap-september-book-of-the-month/',
        amazonUrl: 'https://amzn.to/3X1huhS',
        coverImgUrl: 'https://brandon-quinn.com/wp-content/uploads/2024/09/The-Intelligence-Trap-Book-Cover-431x650.jpg'
    },
    {
        month: 8,
        year: 2024,
        authors: ['Annabelle Dolidon', 'Tessa Sermet'],
        title: 'Continuum',
        subTitle: 'French Science Fiction Short Stories',
        description: html`<p><b>Classic French science fiction short stories translated into English for the first time.</b></p>
            <p>This unique collection of newly translated short stories offers a taste of classic and contemporary French science fiction to English-language readers. These stories cover a range of fascinating topics including simulated reality, speciesism, ecology, and transhumanism—all while exploring universal themes of belonging, death, and identity. Some of the authors featured in this anthology, like Julia Verlanger, Sylvie Denis, or Jean-Claude Dunyach, have shaped the history of French science fiction after World War II. Curated by Annabelle Dolidon and Tessa Sermet, French language and literature professors who share a love for the genre, these nine stories showcase some of the brilliant mid- to late twentieth and twenty-first century French contributions to science fiction.</p>`,
        blogUrl: 'https://brandon-quinn.com/continuum-august-book-of-the-month/',
        amazonUrl: 'https://amzn.to/3WK2WnY',
        coverImgUrl: 'https://brandon-quinn.com/wp-content/uploads/2024/08/51JmVINGZfL1-194x300.jpg'
    },
    {
        month: 7,
        year: 2024,
        authors: ['Freida McFadden'],
        title: 'Never Lie',
        subTitle: '',
        description: html`
            <p><b>NEW YORK TIMES BESTSELLER</b></p>
            <p><b>A twisting, pulse-pounding thriller from the New York Times bestselling author of The Housemaid and The Coworker!</b></p>
            <p><b>Sometimes the truth kills…</b></p>
            <p>Newlyweds Tricia and Ethan are searching for the house of their dreams. They think they've found it when they visit the remote manor that once belonged to Dr. Adrienne Hale, a renowned psychiatrist who vanished without a trace years ago. But when a violent winter storm traps them at the estate, the house begins to lose its appeal.</p>
            <p>Stuck inside and growing restless, Tricia stumbles on a collection of audio transcripts from Dr. Hale's sessions with patients. As Tricia listens to the cassette tapes, she learns about the terrifying chain of events leading up to the doctor's mysterious disappearance.</p>
            <p>With each tape, another shocking piece of the puzzle falls into place, and a web of lies slowly unravels. But by the time Tricia reaches the final cassette, the one that reveals the entire horrifying story, it will be too late…</p>
            <p>From New York Times bestselling author Freida McFadden comes an addictive, unpredictable thriller that will keep you asking the question: what is the truth?</p>
        `,
        blogUrl: 'https://brandon-quinn.com/never-lie-july-book-of-the-month/',
        amazonUrl: 'https://amzn.to/3XH5aWf',
        coverImgUrl: 'https://brandon-quinn.com/wp-content/uploads/2024/07/Never-Lie-Cover-188x300.jpg'
    },
    {
        month: 6,
        year: 2024,
        authors: ['Cixin Liu'],
        title: 'The Three-Body Problem',
        subTitle: '',
        description: html`
            <p><b>The inspiration for the Netflix series <i>3 Body Problem!</i></b></p>
            <p>Set against the backdrop of China's Cultural Revolution, a secret military project sends signals into space to establish contact with aliens. An alien civilization on the brink of destruction captures the signal and plans to invade Earth. Meanwhile, on Earth, different camps start forming, planning to either welcome the superior beings and help them take over a world seen as corrupt, or to fight against the invasion. The result is a science fiction masterpiece of enormous scope and vision.</p>
        `,
        blogUrl: 'https://brandon-quinn.com/the-three-body-problem-june-book-of-the-month/',
        amazonUrl: 'https://amzn.to/4bWQCpT',
        coverImgUrl: 'https://brandon-quinn.com/wp-content/uploads/2024/06/Book-Cover-431x650.jpg'
    },
    {
        month: 5,
        year: 2024,
        authors: ['Arthur Conan Doyle'],
        title: 'Sherlock Holmes',
        subTitle: 'Collection',
        description: html`
            <p>This tastefully produced box set collects Arthur Conan Doyle's Sherlock Holmes stories in six volumes. The drug-addled, anti-social sleuth has become one of the most iconic characters in fiction and the tales collected here will entertain readers today just as much as when they were first published in the late 19th Century. Stories include:</p>
            <ul>
                <li>Adventures of Sherlock Holmes</li>
                <li>The Hound of the Baskervilles & The Valley of Fear</li>
                <li>His Last Bow</li>
                <li>The Memoirs of Sherlock Holmes</li>
                <li>The Return of Sherlock Holmes</li>
                <li>A Study in Scarlet & The Sign of Four</li>
            </ul>
        `,
        blogUrl: 'https://brandon-quinn.com/sherlock-holmes-may-book-of-the-month/',
        amazonUrl: 'https://amzn.to/3QmWQWX',
        coverImgUrl: 'https://brandon-quinn.com/wp-content/uploads/2024/05/book-276x300.png'
    },
    {
        month: 4,
        year: 2024,
        authors: ['Greg Egan'],
        title: 'Diaspora',
        subTitle: 'A Novel',
        description: html`
            <p>Since the Introdus in the twenty-first century, humanity has reconfigured itself drastically. Most chose immortality, joining the polises to become conscious software. Others opted for gleisners: disposable, renewable robotic bodies that remain in contact with the physical world of force and friction. Many of these have left the solar system forever in fusion-drive starships.</p>
            <p>And there are the holdouts: the fleshers left behind in the muck and jungle of Earth—some devolved into dream apes, others cavorting in the seas or the air—while the statics and bridgers try to shape out a roughly human destiny.</p>
            <p>But the complacency of the citizens is shattered when an unforeseen disaster ravages the fleshers and reveals the possibility that the polises themselves might be at risk from bizarre astrophysical processes that seem to violate fundamental laws of nature. The orphan Yatima, a digital being grown from a mind seed, joins a group of citizens and flesher refugees in a search for the knowledge that will guarantee their safety—a search that puts them on the trail of the ancient and elusive Transmuters, who have the power to reshape subatomic particles, and to cross into the macrocosmos, where the universe we know is nothing but a speck in the higher-dimensional vacuum.</p>
        `,
        blogUrl: 'https://brandon-quinn.com/diaspora-april-book-of-the-month/',
        amazonUrl: 'https://amzn.to/3PNCjuG',
        coverImgUrl: 'https://brandon-quinn.com/wp-content/uploads/2024/04/Diaspora-Cover-433x650.jpg'
    },
    {
        month: 3,
        year: 2024,
        authors: ['Arthur C. Clarke'],
        title: '2001, A Space Odyssey',
        subTitle: '',
        description: html`
            <p>From the savannas of Africa at the dawn of mankind to the rings of Saturn as man ventures to the outer rim of our solar system, 2001: A Space Odyssey is a journey unlike any other.</p>
            <p>This allegory about humanity’s exploration of the universe—and the universe’s reaction to humanity—is a hallmark achievement in storytelling that follows the crew of the spacecraft Discovery as they embark on a mission to Saturn. Their vessel is controlled by HAL 9000, an artificially intelligent supercomputer capable of the highest level of cognitive functioning that rivals—and perhaps threatens—the human mind.</p>
            <p>Grappling with space exploration, the perils of technology, and the limits of human power, 2001: A Space Odyssey continues to be an enduring classic of cinematic scope.</p>
        `,
        blogUrl: 'https://brandon-quinn.com/monthly-book-recommendation-march-2001-a-space-odyssey/',
        amazonUrl: 'https://amzn.to/3Tn2k6k',
        coverImgUrl: 'https://brandon-quinn.com/wp-content/uploads/2024/03/71v0Uz2n2GL._SL1500_1-433x650.jpg'
    }
];