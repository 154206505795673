import { AdProviderService } from "../models/ad-provider-service";

export abstract class BaseAdProviderService implements AdProviderService {

    constructor(
        private viewPercentace: number
    ) {}

    async initialize(delay: number): Promise<void> {
        if (Math.random()*100 < this.viewPercentace) {
            try {
                return await this.doInitialize(delay);
            } catch (err) {
                console.warn(`[BaseAdProviderService] error caught:`, err);
                throw err;
            }
        }
        return;
    }
    
    protected abstract doInitialize(delay: number): Promise<void>;
}