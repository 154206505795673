import { ClientDispatcherService } from "../client-dispatcher-service/client-dispatcher-service";
import { ClientService } from "../client-service/client-service";
import { HexakaiBoardData } from "../hexakai-board/hexakai-board-data";
import { GameAchievement } from "../models/game-achievement";
import { GameAchievementDisplayStrategy } from "../models/game-achievement-display-strategy";
import { GameAchievementRecordService } from "../models/game-achievement-record-service";
import { HexakaiGameDifficulties, HexakaiGameDifficulty } from "../models/hexakai-game-params";
import { StorageService } from "../models/storage-service";
import { ToastService } from "../toast-service/toast-service";
import { GameAchievementRecordEmitStrategy } from "./record-strategies/emit-strategy";
import { GameAchievementRecordInAppStrategy } from "./record-strategies/in-app-strategy";
import { GameAchievementDisplayEmitStrategy } from "./display-strategies/emit-strategy";
import { GameAchievementDisplayInAppStrategy } from "./display-strategies/in-app-strategy";

/**
 * Handles emitting when game achievements have been made
 */
export class GameAchievementService {

    // hacky way to enforce singleton
    private static instance: GameAchievementService;
    
    // record how to emit
    private displayStrategy!: GameAchievementDisplayStrategy;
    private recordService!: GameAchievementRecordService;

    constructor(
        private clientService: ClientService,
        storageService: StorageService,
        toastService: ToastService,
        clientDispatcherService: ClientDispatcherService
    ) {
        if (GameAchievementService.instance) {
            return GameAchievementService.instance;
        }

        GameAchievementService.instance = this;

        switch (clientService.getConfig().gameAchievements.emitStrategy) {
            case 'emit-in-javascript':
                this.recordService = new GameAchievementRecordEmitStrategy(clientDispatcherService);

                if (this.clientService.getConfig().gameAchievements.displayToast) {
                    this.displayStrategy = new GameAchievementDisplayInAppStrategy(
                        this.recordService,
                        toastService,
                        false
                    );
                } else {
                    this.displayStrategy = new GameAchievementDisplayEmitStrategy(this.recordService);
                }

                break;
            default:
                this.recordService = new GameAchievementRecordInAppStrategy(storageService);
                this.displayStrategy = new GameAchievementDisplayInAppStrategy(
                    this.recordService,
                    toastService,
                    true
                );
                break;
        }
    }

    registerGameCompleted(gameSize: number, gameDifficulty: HexakaiGameDifficulty): void {
        this.displayStrategy.dispatch(
            GameAchievementService.getAchievementFromGame(gameSize, gameDifficulty)
        );
    }

    getAchievements(): Promise<GameAchievement[]> {
        return this.recordService.getAchievements();
    }

    async getDebugInfo(): Promise<any> {
        return {
            recordStrategy: this.recordService.getServiceName(),
            displayStrategy: this.displayStrategy.getServiceName(),
            unlockedAchievements: 
                await Promise.race([
                    this.recordService.getAchievements().catch((err) => "error loading " + err),
                    new Promise(resolve => setTimeout(() => resolve('Not displaying due to timeout'), 5_000))
                ])
        };
    }

    static getAllAchievements(): GameAchievement[] {
        const achievements: GameAchievement[] = [];

        for (const gameSize of HexakaiBoardData.GAME_SIZES) {
            if (gameSize === 1) {
                continue;
            }
            for (const difficulty of HexakaiGameDifficulties) {
                achievements.push(this.getAchievementFromGame(gameSize, difficulty));
            }
        }

        return achievements;
    }

    static getAchievementFromId(id: string): GameAchievement {
        const [size, difficulty] = id.split("-");
        if (!size || !difficulty) {
            throw new Error(`Invalid achievement id: "${id}"`);
        }

        return this.getAchievementFromGame(size, difficulty);
    }

    static getAchievementFromGame(gameSize: number | string, gameDifficulty: HexakaiGameDifficulty | string): GameAchievement {
        return {
            id: gameSize + "-" + gameDifficulty,
            name: `Complete a board of size ${gameSize} on ${gameDifficulty} mode`,
            iconUrl: `./achievement-icon-${gameSize}-${gameDifficulty.replace(/ /g, "-").toLowerCase()}.png`
        };
    }

}