export class PromiseQueue {

    private static queueMap = new Map<string, Promise<any>>();

    public static push<T>(queueName: string, fn: () => Promise<T>): Promise<T> {
        if (!this.queueMap.has(queueName)) {
            this.queueMap.set(queueName, Promise.resolve());
        }

        const newPromise = this.queueMap.get(queueName)!.then(() => {
            return fn();
        });
        this.queueMap.set(queueName, newPromise.catch(err => {}));
        return newPromise;
    }

}