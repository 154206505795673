import { ClientDispatcherService } from "../../client-dispatcher-service/client-dispatcher-service";
import { GameAchievement } from "../../models/game-achievement";
import { GameAchievementRecordService } from "../../models/game-achievement-record-service";
import { GameAchievementService } from "../game-achievement-service";

export class GameAchievementRecordEmitStrategy implements GameAchievementRecordService {

    constructor(
        private clientDispatcherService: ClientDispatcherService
    ) { }

    getServiceName(): string {
        return "Record Service Emit";
    }

    async getAchievements(): Promise<GameAchievement[]> {
        const achievementIds = await this.getAchievementIds();
        try {
            return achievementIds.map(id => GameAchievementService.getAchievementFromId(id));
        } catch (err) {
            throw new Error(`Error while parsing achievements: "${JSON.stringify(achievementIds)}" - ${err}`)
        }
    }

    async hasAchievement(id: string): Promise<boolean> {
        const achivementIds = await this.getAchievementIds();
        return achivementIds.includes(id);
    }

    async recordAchievement(achievement: GameAchievement): Promise<void> {
        console.log("[GameAchievementRecordEmitStrategy] sending achievement", achievement)
        this.clientDispatcherService.dispatch('achievement', achievement);
    }

    private async getAchievementIds(): Promise<string[]> {
        console.log("[GameAchievementRecordEmitStrategy] requesting records");
        const data: string = await this.clientDispatcherService.request('achievement-fetch', {}, 10_000, 1);
        const listOfIds = data ? data.split(",") : [];
        console.log("[GameAchievementRecordEmitStrategy] received response", data);
        return listOfIds;
    }
}