// src/toast-notification.ts
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('toast-notification')
export class ToastNotification extends LitElement {
    @property({ type: String }) title: string = '';
    @property({ type: String }) message: any = '';
    @property({ type: String }) icon: string = ''; // URL to the icon image
    @property({ type: Boolean, reflect: true }) visible: boolean = false;
    @property({ type: Number }) duration: number = 10000;

    static styles = css`
    :host {
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%) translateY(100%);
      z-index: 1000;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 15px 25px;
      background: #323232;
      color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      opacity: 0;
      transition: opacity 0.3s ease, transform 0.3s ease;
      text-align: left;
      cursor: pointer;
      max-width: 500px;
      width: 90%;
      box-sizing: border-box;
    }
    :host([visible]) {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
    .icon {
      width: 55px;
      height: 55px;
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      flex-grow: 1;
    }
    .title {
      font-weight: bold;
    }
    .message {
      flex-grow: 1;
    }
    .close {
      align-self: flex-start;
      cursor: pointer;
      font-size: 20px;
    }

    /* Adjust layout for mobile devices */
    @media screen and (max-width: 480px) {
      :host {
        max-width: 90%;
        bottom: 10px;
        padding: 10px 15px;
      }
      .icon {
        width: 45px;
        height: 45px;
      }
      .close {
        font-size: 18px;
      }
    }
  `;

    private _timeout: number | undefined;

    firstUpdated() {
        if (this.visible) {
            this._startTimer();
        }
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('visible') && this.visible) {
            this._startTimer();
        }
    }

    private _startTimer() {
        clearTimeout(this._timeout);
        if (this.duration !== Infinity) {
          this._timeout = window.setTimeout(() => {
              this._hideToast();
          }, this.duration);
        }
    }

    private _hideToast() {
        this.visible = false;
    }

    private _closeToast() {
        clearTimeout(this._timeout);
        this.visible = false;
    }

    private _onToastClick() {
        this._closeToast();
    }

    render() {
        return html`
            ${this.icon ? html`<img class="icon" src="${this.icon}" alt="Icon" />` : ``}
            <div class="content">
                <span class="title">${this.title}</span>
                <span class="message">${this.message}</span>
            </div>
            <span class="close" @click=${this._closeToast}>&times;</span>
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('click', this._onToastClick);
    }

    disconnectedCallback() {
        this.removeEventListener('click', this._onToastClick);
        super.disconnectedCallback();
    }
}
