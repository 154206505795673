import { AdProviders, ClientConfig } from "../models/client-config";
import { WEB_BROWSER_CONFIG } from "./web-browser-config";

export const LOCALHOST_CONFIG: ClientConfig = {
    ...WEB_BROWSER_CONFIG,
    body: {
        ...WEB_BROWSER_CONFIG.body,
        gameCompleteAdVisible: false,
        styledButton: {
            pointerUpCssForce: false
        },
        navMenuSubmenuEnabled: true,
        fullScreenEnabled: true
    },
    tutorial: {
        type: "interactive"
    },
    gameBoard: {
        ...WEB_BROWSER_CONFIG.gameBoard,
        showCellConstraintsHover: true,
        generatorPatternCreate: {
            enabled: true,
            experimentalEnabled: true
        },
        hexCell: {
            rightClickStrategy: 'wait',
            numPencilsVisible: 3
        }
    },
    analytics: {
        logEvents: false
    },
    dailyPuzzles: {
        future: true
    },
    controls: {
        window: true
    },
    themes: {
        extendedThemesEnabled: true
    },
    share: {
        navigatorEnabled: false
    },
    ads: {
        requestDisplayPermission: {
            enabled: false,
        },
        adProviders: [{
            provider: AdProviders.bookOfTheMonth,
            percentage: 0
        }, {
            provider: AdProviders.authorBook,
            percentage: 0
        },
        {
            provider: AdProviders.google,
            percentage: 100
        }],
        viewPercentage: 0
    },
};